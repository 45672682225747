import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";

export default function (alertConfigUuid: string): Promise<any> {
  return apolloClient.mutate({
    mutation: gql`
      mutation ($alertConfigUuid: ID!) {
        deleteAlertConfig(alertConfigUuid: $alertConfigUuid)
      }
    `,
    variables: {
      alertConfigUuid: alertConfigUuid
    }
  });
}
