import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { Contact } from "@/types";

export default function (contact: Contact, assetUuid: string, parameterName: string): Promise<any> {
  return apolloClient.mutate({
    mutation: gql`
      mutation ($assetUuid: ID!, $contactUuid: ID!, $parameterName: String!) {
        createAlertConfig(
          assetUuid: $assetUuid
          alertConfig: { parameterName: $parameterName }
          recipient: { contactInfoUuid: $contactUuid }
        )
      }
    `,
    variables: {
      assetUuid: assetUuid,
      contactUuid: contact.contactInfoUuid,
      parameterName: parameterName
    }
  });
}
