import { ref, Ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { User, Contact } from "@/types";

export interface UseUsersWithAccessToResourceResult {
  users: Ref<User[]>;
  contacts: Ref<Contact[]>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

// for now we only support asset resources
export function useUsersWithAccessToResource(
  resourceType: string,
  resourceUuid: string
): UseUsersWithAccessToResourceResult {
  const users: Ref<User[]> = ref([]);
  const contacts: Ref<Contact[]> = ref([]);
  const loadingError = ref(false);

  // these will be set by examining resource type
  const resourceTable = resourceType === "Asset" ? "ubx_asset" : "";
  const resourceKey = "asset_uuid";

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query ResourceUsersQuery($resourceTable: String!, $resourceKey: String!, $resourceUuid: ID!) {
        users: resourceUsers(resourceTable: $resourceTable, resourceKey: $resourceKey, resourceUuid: $resourceUuid) {
          emailAddress
          activated
          userUuid

          contactInfo {
            accountUuid
            contactInfoUuid
            emailAddress
            emailEnable
            name
            ownerUuid
            phoneNumber
            smsEnable
            isUserContactInfo
          }
        }
      }
    `,
    { resourceTable: resourceTable, resourceKey: resourceKey, resourceUuid: resourceUuid }
  );

  onError(() => {
    loadingError.value = true;
  });
  onResult(queryResult => {
    if (queryResult.data) {
      users.value = queryResult.data.users;
      contacts.value = queryResult.data.users.map((user: User) => user.contactInfo).filter(Boolean);
    }
  });

  return { users, contacts, loadingError, refetch, loading };
}
