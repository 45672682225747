import { ref, unref, computed, Ref, ComputedRef } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import createAlertConfig from "@/gql/create-alert-config-mutation";
import deleteAlertConfig from "@/gql/delete-alert-config-mutation";
import { AlertConfig, AlertRecipient, Contact, MaybeRef } from "@/types";
import { debounce } from "lodash";

export interface UsePropertyAlertConfigsResult {
  alertConfigs: Ref<AlertConfig[]>;
  loading: Ref<boolean>;
  loadingError: Ref<boolean>;
  loadAlertConfigs: () => void;
  contactSubscriptions: ComputedRef<Contact[]>;
  subscriptionsCount: ComputedRef<number>;
  userSubscriptions: ComputedRef<Contact[]>;
  allSubscriptions: ComputedRef<Contact[]>;
  createPropertyAlertConfig: (contact: Contact) => void;
  deletePropertyAlertConfig: (contact: Contact) => void;
}

//
// Used to manage alertConfigs for a property on an asset
// AlertCofigs are not loaded by default, use `loadAlertConfigs` to start loading
// it will return a promise for chaining which is resolved once the query is complete
//
export function usePropertyAlertConfigs(
  assetUuid: MaybeRef<string>,
  propertyName: MaybeRef<string>
): UsePropertyAlertConfigsResult {
  const alertConfigs: Ref<AlertConfig[]> = ref([]);
  // loading and refreshInProgress are similar but not the same
  // loading is exposed to the caller and used to indicate any loading activity
  // refreshInProgress is internal and used to short circuit saving alert configs when there is a pending refresh
  const loading = ref(false);
  const refreshInProgress = ref(false);
  const loadingError = ref(false);

  const { onResult, onError, load, refetch } = useLazyQuery(
    gql`
      query AssetAlertConfigsQuery($assetUuid: ID!, $parameterName: String!) {
        alertConfigs: assetAlertConfigs(assetUuid: $assetUuid, parameterName: $parameterName) {
          alertConfigUuid
          alertName
          parameterName
          timeout
          enabled
          recipients {
            alertRecipientUuid
            contactInfoUuid
            enable
            contactInfo {
              accountUuid
              contactInfoUuid
              emailAddress
              emailEnable
              name
              ownerUuid
              phoneNumber
              smsEnable
              isUserContactInfo
            }
          }
        }
      }
    `,
    { assetUuid: unref(assetUuid), parameterName: unref(propertyName) },
    {
      fetchPolicy: "no-cache",
      // added due to loading stats issue with apollo
      // https://github.com/vuejs/apollo/issues/501
      notifyOnNetworkStatusChange: true
    }
  );

  const loadAlertConfigs = (): Promise<any> | undefined => {
    loading.value = true;

    return load() || refetch();
  };

  onError(() => {
    loading.value = false;
    loadingError.value = true;
    refreshInProgress.value = false;
  });

  onResult(queryResult => {
    if (!queryResult.loading) {
      if (queryResult.data && !refreshInProgress.value) {
        alertConfigs.value = queryResult.data.alertConfigs.filter(
          (config: AlertConfig) => config.parameterName === unref(propertyName) && config.enabled
        );
      }
      loading.value = false;
      refreshInProgress.value = false;
    }
  });

  const contactSubscriptions = computed(() => {
    if (!alertConfigs.value) return [] as Contact[];

    const subscribedContacts: Contact[] = [];
    alertConfigs.value
      .filter((config: AlertConfig) => config.enabled)
      .forEach((config: AlertConfig) => {
        config.recipients.forEach((recipient: AlertRecipient) => {
          if (!recipient.contactInfo.isUserContactInfo) subscribedContacts.push(recipient.contactInfo);
        });
      });
    return subscribedContacts;
  });

  const userSubscriptions = computed(() => {
    if (!alertConfigs.value) return [] as Contact[];

    const subscribedUserContacts: Contact[] = [];
    alertConfigs.value
      .filter((config: AlertConfig) => config.enabled)
      .forEach((config: AlertConfig) => {
        config.recipients.forEach((recipient: AlertRecipient) => {
          if (recipient.contactInfo.isUserContactInfo) subscribedUserContacts.push(recipient.contactInfo);
        });
      });
    return subscribedUserContacts;
  });

  const allSubscriptions = computed(() => {
    if (!alertConfigs.value) return [] as Contact[];

    const subscribedContacts: Contact[] = [];
    alertConfigs.value
      .filter((config: AlertConfig) => config.enabled)
      .forEach((config: AlertConfig) => {
        config.recipients.forEach((recipient: AlertRecipient) => {
          subscribedContacts.push(recipient.contactInfo);
        });
      });
    return subscribedContacts;
  });

  const subscriptionsCount = computed(() => {
    return allSubscriptions.value.length;
  });

  const createPropertyAlertConfig = (contact: Contact) => {
    // don't create if it already exists
    if (
      alertConfigs.value.some((config: AlertConfig) => {
        config.recipients.some((recipient: AlertRecipient) => recipient.contactInfoUuid == contact.contactInfoUuid);
      })
    )
      return;

    const placeHolder: AlertConfig = buildRecipientPlaceholder(
      uuidBasedOnContact(contact, "alert-alert-config"),
      unref(assetUuid),
      unref(propertyName),
      contact
    );
    alertConfigs.value.push(placeHolder);

    loading.value = true;
    refreshInProgress.value = true;
    createAlertConfig(contact, unref(assetUuid), unref(propertyName)).then(response => {
      placeHolder.alertConfigUuid = response.data.createAlertConfig;
      debouncedRefresh();
    });
  };

  const deletePropertyAlertConfig = (contact: Contact) => {
    let alertConfigUuid: string | undefined;
    alertConfigs.value.forEach((config: AlertConfig) => {
      config.recipients.forEach((recipient: AlertRecipient) => {
        if (!alertConfigUuid && recipient.contactInfoUuid == contact.contactInfoUuid) {
          alertConfigUuid = config.alertConfigUuid;
          config.enabled = false;
        }
      });
    });

    if (alertConfigUuid && !alertConfigUuid.includes("alert-config")) {
      loading.value = true;
      refreshInProgress.value = true;
      deleteAlertConfig(alertConfigUuid).then(() => debouncedRefresh());
    } else {
      debouncedRefresh();
    }
  };

  function buildRecipientPlaceholder(
    alertConfigUuid: string,
    assetUuid: string,
    propertyName: string,
    contact: Contact
  ): AlertConfig {
    return {
      alertConfigUuid: alertConfigUuid,
      parameterName: propertyName,
      enabled: true,
      recipients: [
        {
          alertRecipientUuid: uuidBasedOnContact(contact, "alert-recipient"),
          contactInfoUuid: contact.contactInfoUuid,
          contactInfo: contact,
          enable: true
        }
      ],
      buildingUuid: uuidBasedOnContact(contact, "building"),
      ownerUuid: uuidBasedOnContact(contact, "owner"),
      assetUuid: assetUuid
    };
  }

  function uuidBasedOnContact(contact: Contact, identifier: string): string {
    return `${identifier}${contact.contactInfoUuid}`;
  }

  const debouncedRefresh = debounce(() => {
    refetch();
  }, 1000);

  return {
    alertConfigs,
    loading,
    loadingError,
    loadAlertConfigs,
    contactSubscriptions,
    userSubscriptions,
    allSubscriptions,
    subscriptionsCount,
    createPropertyAlertConfig,
    deletePropertyAlertConfig
  };
}
