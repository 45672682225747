import gql from "graphql-tag";
import { apolloClient } from "@/plugins/vue-apollo";
import { ThresholdParent, ThresholdPair } from "@/types";

export default function (
  parentType: ThresholdParent,
  parentUuid: string,
  propertyName: string,
  thresholds: ThresholdPair[]
): Promise<any> {
  const parameters = {
    parentType,
    parentUuid,
    propertyName,
    thresholds
  };

  return apolloClient
    .mutate({
      mutation: gql`
        mutation ($parameters: SetThresholdParams!) {
          setThresholds(parameters: $parameters) {
            statusCode
          }
        }
      `,
      variables: { parameters }
    })
    .then(response => {
      return response.data.setThresholds;
    });
}
